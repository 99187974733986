@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.cdnfonts.com/css/codec-pro");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  html,
  body,
  #root {
    height: 100%;
    margin: 0;
  }

  body {
    display: flex;
    flex-direction: column;
  }

  #root {
    flex: 1;
  }

  body {
    @apply text-app-grey;
    font-family: "Inter", sans-serif;
  }

  label {
    @apply text-sm font-medium leading-5;
  }

  input,
  textarea,
  select {
    @apply bg-transparent ring-1 ring-inset ring-app-grey-lighter rounded-lg py-2.5 px-3 text-base leading-6 focus:ring-inset focus:ring-app-blue focus:outline-none transition-colors placeholder:text-app-grey-lighter shadow-xs;
  }

  input[type="number"] {
    @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none;
  }
}

@layer components {
  .font-codec {
    font-family: "Codec Pro", sans-serif;
  }
}
